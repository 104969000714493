import { Box, Typography } from "@mui/material";
import React from "react";
import "./styles.css";

const AppLoadingIndicator = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        background: "#fff",
        color: "primary.main",
        zIndex: 10000,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box display={"flex"} alignItems="center">
        <Box class="dots-bars-4" />
        <Typography variant="body" sx={{ ml: 2, mb: 1 }}>
          Hang on, We're preparing your content.
        </Typography>
      </Box>
    </Box>
  );
};

export default AppLoadingIndicator;
