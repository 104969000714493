import { Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import image from "../../../assets/bg.jpg";

export const StyledAuthBackground = styled(Box)(({ theme }) => ({
  height: "100vh",
  backgroundImage: `url(${image})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  width: "100%",
}));

export const StyledWrapper = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.body.secondary,
}));
