import React from "react";
import { Box, Typography } from "@mui/material";

import { StyledAuthBackground, StyledWrapper } from "./Styles";

const Wrapper = ({ children, slogan }) => {
  return (
    <>
      <StyledAuthBackground>
        <Box
          sx={{
            width: "50%",
            height: { xs: "100%", md: "100%" },
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background:
              "linear-gradient(121deg, rgba(77,0,77,0.8) 0%, rgba(179,41,179,1) 100%)",
          }}
        >
          <Box
            sx={{
              flex: "1 1 auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "body.secondary", fontSize: 160 }}>
              Meklit
            </Typography>
            <Typography
              sx={{
                fontFamily: "inter",
                fontWeight: "200",
                fontSize: 35,
                color: "body.secondary",
              }}
            >
              {slogan}
            </Typography>
            <Typography
              sx={{
                fontFamily: "inter",
                fontWeight: "50",
                fontSize: 25,
                color: "body.secondary",
              }}
            >
              Event Registration
            </Typography>
          </Box>

          <Typography
            sx={{
              fontFamily: "inter",
              fontWeight: "200",
              fontSize: 20,
              color: "body.secondary",
              marginBottom: 7,
            }}
          >
            &copy; {new Date().getFullYear()} Meklit. All rights reserved.
          </Typography>
        </Box>

        <StyledWrapper sx={{ width: { xs: "100%", md: "50%" } }}>
          {children}
        </StyledWrapper>
      </StyledAuthBackground>
    </>
  );
};

export default Wrapper;
