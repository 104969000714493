import * as React from "react";
import hotToast, { Toaster as HotToaster } from "react-hot-toast";
import { Alert, Snackbar } from "@mui/material";

export const Toaster = HotToaster;

function Toast({ visible, message, onClose, ...props }) {
  return (
    <Snackbar open={visible} autoHideDuration={props.duration}>
      <Alert onClose={onClose} sx={{ width: "100%" }} {...props}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export function toast(opts) {
  const { title, message, severity = "success", duration = null } = opts;

  return hotToast.custom(({ visible, id }) => (
    <Toast
      visible={visible}
      title={title}
      message={message}
      severity={severity}
      duration={duration ? duration : severity === "success" ? 3000 : 5000}
      onClose={() => {
        hotToast.dismiss(id);
      }}
    />
  ));
}
