import { Box } from "@mui/material";
import Logo from "../../../assets/logo.png";
import EventRegistrationWrapper from "../../wrappers/EventRegistrationWrapper";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import {
  FormikPhoneField,
  FormikSelectField,
  FormikTextField,
  SubmitButton,
} from "../CustomFormikFields";
import * as yup from "yup";
import axios from "axios";
import { BASE_URL } from "../../../constants/url";
import { toast, Toaster } from "../../toast";
import AppLoadingIndicator from "../../AppLoadingIndicator"

const validationSchema = yup.object({
  fullname: yup.string().required("Required"),
  gender: yup.string().required("Required").oneOf(["ወንድ", "ሴት"]),
  age: yup.number().required("Required")
    .min(15, "ዕድሜ ቢያንስ 15 ዓመት መሆን አለበት")
    .max(100, "ዕድሜ ከ 100 ዓመት መብለጥ አይችልም"),
  email: yup.string().required("Required"),
  phone: yup.string().required("Required"),
  martialStatus: yup.string().required("Required").oneOf(["ያገባ", "ያላገባ"]),
  partnerStatus: yup.string().required("Required").oneOf(["አይ", "አው"]),
  event: yup.string().required("Required"),
  food: yup.string(),
  aboutUs: yup.string(),
});

const RegistrationForm = () => {
  const url = BASE_URL;
  const [isLoading, setIsLoading] = useState(true);
  const [foodOptions, setFoodOptions] = useState([]);
  const [eventOptions, setEventOptions] = useState([]);
  const [aboutUsOptions, setAboutUsOptions] = useState([]);

  const getFoodOptions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/foods`);
      if (response.status === 200) {
        setFoodOptions(response.data);
      } else {
        console.log("An error occurred.");
      }
    } catch (error) {
      console.log("An error occurred.", error);
    }
  };

  const getEventOptions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/events`);
      if (response.status === 200) {
        setEventOptions(response.data);
      } else {
        console.log("An error occurred.");
      }
    } catch (error) {
      console.log("An error occurred.", error);
    }
  };
  const getAboutUsOptions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/about-us`);
      if (response.status === 200) {
        setAboutUsOptions(response.data);
      } else {
        console.log("An error occurred.");
      }
    } catch (error) {
      console.log("An error occurred.", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          getFoodOptions(),
          getEventOptions(),
          getAboutUsOptions(),
        ]);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <AppLoadingIndicator />;
  }

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);

    const formData = {
      fullname: values.fullname,
      gender: values.gender,
      age: values.age,
      email: values.email,
      phone: values.phone.replace("+251", "0"),
      martialStatus: values.martialStatus,
      partnerStatus: values.partnerStatus,
      event: values.event,
      food: values.food,
      food2: values.food2,
      aboutUs: values.aboutUs,
      aboutUs2: values.aboutUs2,
    };

    try {
      const response = await axios.post(`${url}/addRows`, formData);

      if (response.status === 200) {
        console.log("Successfully added rows.");
        resetForm();
        toast({ title: "Success", message: "በትክክል ተመዝግበዋል" });
      } else {
        toast({
          title: "Error",
          message: "ይቅርታ በእኛ በኩል ስህተት ተፈጥሯል፣ በኋላ መሞከር ይችላሉ።",
          severity: "error",
        });
        console.log("An error occurred.");
      }
    } catch (error) {
      toast({
        title: "Error",
        message: "ይቅርታ በእኛ በኩል ስህተት ተፈጥሯል፣ በኋላ መሞከር ይችላሉ።",
        severity: "error",
      });
      console.log("An error occurred.", error);
    }

    setIsLoading(false);
  };
  if (eventOptions.length === 0) {
    return (
      <EventRegistrationWrapper slogan={"Fulfilling humankind's potential"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // Center the content horizontally
            alignItems: "center", // Center the content vertically
          }}
        >
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              padding: { xs: 2, md: 3 },
              backgroundColor: "#f5f5f5",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              borderRadius: "16px",
              fontFamily: "Arial, sans-serif",
              fontSize: "25px",
              fontWeight: "bold",
              lineHeight: "3.5",
              color: "#333",
            }}
          >
            ይቅርታ ለጊዜው ምንም ዝግጅቶች የሉንም
          </Box>
        </Box>
      </EventRegistrationWrapper>
    );
  }

  return (
    <EventRegistrationWrapper slogan={"Fulfilling humankind's potential"}>
      <Box
        sx={{
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <Toaster position="bottom-left" />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={Logo} style={{ width: 100, marginBottom: 1 }} />
        </Box>
        <Formik
          initialValues={{
            fullname: "",
            gender: "",
            age: 0,
            email: "",
            phone: "",
            martialStatus: "",
            partnerStatus: "",
            event: "",
            food: "",
            food2: "",
            aboutUs: "",
            aboutUs2: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, resetForm }) => (
            <Form style={{ padding: "0 5rem" }}>
              <FormikTextField required name="fullname" label="ሙሉ ስም" />
              <FormikSelectField
                required
                name="gender"
                label="ፆታ"
                options={["ወንድ", "ሴት"]}
              />
              <FormikTextField required name="age" label="እድሜ" type="number" />
              <FormikTextField required name="email" label="ኢሜል" />
              <FormikPhoneField required name="phone" label="ስልክ ቁጥር" />
              <FormikSelectField
                required
                name="martialStatus"
                label="ትዳር"
                options={["ያገባ", "ያላገባ"]}
              />
              <FormikSelectField
                required
                name="partnerStatus"
                label="ለፕሮግራሙ ባለቤቴን ይዤ እመጣለው"
                options={["አይ", "አው"]}
              />
              <FormikSelectField
                required
                name="event"
                label="መሳተፍ የምፈልገው ፕሮግራም እና ቀን"
                options={eventOptions}
              />
              {foodOptions.length > 0 && (
                <FormikSelectField
                  required
                  name="food"
                  label="ለምሳ መብላት የምፈልገው"
                  options={foodOptions}
                />
              )}
              {values.food &&
                foodOptions.find((option) => option.label === values.food)
                  .options.length > 0 && (
                  <FormikSelectField
                    name="food2"
                    label="ስለመክሊት በምን መንገድ ሊያውቁ ቻሉ"
                    required
                    options={
                      foodOptions.find(
                        (option) => option.label === values.food
                      ).options
                    }
                  />
                )}
              {aboutUsOptions.length > 0 && (
                <FormikSelectField
                  name="aboutUs"
                  label="ስለመክሊት በምን መንገድ ሊያውቁ ቻሉ"
                  options={aboutUsOptions}
                />
              )}
              {values.aboutUs &&
                aboutUsOptions.find((option) => option.label === values.aboutUs)
                  .options.length > 0 && (
                  <FormikSelectField
                    name="aboutUs2"
                    label="ስለመክሊት በምን መንገድ ሊያውቁ ቻሉ"
                    required
                    options={
                      aboutUsOptions.find(
                        (option) => option.label === values.aboutUs
                      ).options
                    }
                  />
                )}
              <SubmitButton
                text="Register"
                type="submit"
                isSubmitting={isLoading}
                style={{ margin: "40px 0" }}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </EventRegistrationWrapper>
  );
};

export default RegistrationForm;
