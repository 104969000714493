import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Switch,
  useTheme,
  Chip,
  Grid,
  Checkbox,
  FormLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import { CancelSharp } from "@mui/icons-material";
import {
  MobileDatePicker,
  DateTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { useField } from "formik";
import { MuiTelInput } from "mui-tel-input";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledChip = styled(Chip)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fcfcfb",
  ".MuiChip-deleteIcon": {
    color: "#fcfcfb",
  },
  "&:hover": {
    background: "transparent",
    color: theme.palette.primary.main,
    ".MuiChip-deleteIcon": {
      color: theme.palette.primary.main,
    },
  },
}));

export const FormikTextField = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <TextField
      fullWidth
      variant="standard"
      sx={{ my: 1 }}
      label={label}
      {...field}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      {...props}
    />
  );
};

export const FormikTextAreaField = (props) => {
  return <FormikTextField {...props} multiline rows={4} maxRows={4} />;
};

export const FormikPasswordField = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <TextField
      fullWidth
      variant="standard"
      sx={{ my: 1 }}
      label={label}
      {...props}
      {...field}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            style={{ border: "0pc solid transparent" }}
          >
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};

export const FormikSwitchField = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={field.value}
            {...props}
            onChange={(e) => helpers.setValue(e.target.checked)}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

export const FormikCheckboxField = ({ name, label, options, ...props }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <FormControl
      fullWidth
      variant="standard"
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      sx={{ my: 1 }}
      {...props}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {options.map((item) => (
          <FormControlLabel
            key={item}
            control={
              <Checkbox
                checked={field.value ? field.value[item] : false}
                onChange={(event) =>
                  helpers.setValue({
                    ...field.value,
                    [event.target.name]: event.target.checked,
                  })
                }
                name={item}
              />
            }
            label={item}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export const FormikSelectField = ({ name, label, options, ...props }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <FormControl
      fullWidth
      variant="standard"
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      sx={{ my: 1 }}
      {...props}
    >
      <InputLabel id={`${props.name}Label`}>{label}</InputLabel>
      <Select
        fullWidth
        variant="standard"
        label={label}
        {...field}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        {...props}
        renderValue={(value) => value.slice(0, 30)}
      >
        {options.map((item) => (
          <MenuItem
            key={typeof item === "string" ? item : item.value}
            style={{ whiteSpace: "normal" }}
            value={typeof item?.value === "undefined" ? item : item.value}
          >
            {item?.label || item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const FormikSelectMultipleChipField = (props) => {
  const { name, label, options, disabled = false, ...rest } = props;

  const [field, meta, helpers] = useField(name);
  const { value: selectedValue } = field;
  const { setValue } = helpers;

  const handleDelete = (item) => () => {
    const newItem = selectedValue.filter((elem) => elem !== item);
    setValue(newItem);
  };

  const findItem = (value) => {
    const item = options.find((item) => item.value === value);
    if (item) {
      return item.label;
    }
    return "";
  };

  return (
    <FormControl
      fullWidth
      {...rest}
      disabled={disabled}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      sx={{ my: 1 }}
    >
      <InputLabel id={`${props.name}Label`}>{label}</InputLabel>
      <Select
        variant="standard"
        label={label}
        multiple
        value={selectedValue}
        {...props}
        {...field}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <StyledChip
                disabled={disabled}
                deleteIcon={
                  <CancelSharp
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
                key={value}
                label={findItem(value)}
                onDelete={handleDelete(value)}
                sx={{
                  "text-fill-color": "white",
                }}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      >
        {options.map((item) => (
          <MenuItem value={item?.value || item}>{item?.label || item}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const FormikSelectWithImageField = ({
  name,
  label,
  options,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const theme = useTheme();

  return (
    <Box sx={{ my: 1 }}>
      <Typography variant="body" sx={{ mb: 0.5 }}>
        {label}
      </Typography>
      <Grid container spacing={2}>
        {options.map((item, index) => (
          <Grid key={item.value} item xs={12} sm={4} md={3} lg={3} xl={2}>
            <Box
              onClick={() => {
                helpers.setValue(item.value);
              }}
              sx={{
                cursor: "pointer",
                border: `${field.value === item.value ? "2px" : "0px"} solid ${
                  theme.palette.primary.main
                }`,
                borderRadius: "3px",
                p: 0.15,
              }}
            >
              <img
                src={item.label}
                alt={item.value}
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "100px",
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      {meta.error && meta.touched ? (
        <Typography variant="p" color="error.main" sx={{ mt: 0.5 }}>
          {meta.error}
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};

export const FormikPhoneField = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <MuiTelInput
      fullWidth
      variant="standard"
      sx={{ my: 1 }}
      label={label}
      defaultCountry="ET"
      value={field.value}
      {...field}
      {...props}
      onChange={(value) => helpers.setValue(value)}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};

export const FormikDateField = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileDatePicker
        InputProps={{
          variant: "standard",
        }}
        sx={{ my: 1 }}
        label={label}
        {...field}
        {...props}
        onChange={(value) => helpers.setValue(value)}
        slotProps={{
          textField: {
            variant: "standard",
            fullWidth: true,
            error: meta.touched && Boolean(meta.error),
            helperText: meta.touched && meta.error,
            ...props,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export const FormikDateTimeField = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        InputProps={{
          variant: "standard",
        }}
        sx={{ my: 1 }}
        label={label}
        {...field}
        {...props}
        onChange={(value) => helpers.setValue(value)}
        slotProps={{
          textField: {
            variant: "standard",
            fullWidth: true,
            error: meta.touched && Boolean(meta.error),
            helperText: meta.touched && meta.error,
            ...props,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export const FormikTimeField = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimePicker
        InputProps={{
          variant: "standard",
        }}
        sx={{ my: 1 }}
        label={label}
        {...field}
        {...props}
        onChange={(value) => helpers.setValue(value)}
        slotProps={{
          textField: {
            variant: "standard",
            fullWidth: true,
            error: meta.touched && Boolean(meta.error),
            helperText: meta.touched && meta.error,
            ...props,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export const FormikFileField = ({ label, accept, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [value, setValue] = useState(null);
  useEffect(() => {
    if (field.value) {
      setValue(field.value.name);
    }
  }, []);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Button
          variant="outlined"
          component="label"
          sx={{ my: 1, color: "black" }}
          {...props}
        >
          <AttachFileIcon sx={{ width: "18px", height: "18px" }} /> &nbsp;&nbsp;
          {label}
          <input
            accept={accept}
            type="file"
            hidden
            onChange={(e) => {
              helpers.setValue(e.target.files[0]);
              setValue(e.target.files[0].name);
            }}
          />
        </Button>
        {value && <Typography sx={{ ml: 2 }}>{value}</Typography>}
      </Box>
      {Boolean(meta.error) && (
        <Typography sx={{ color: "error.main" }}>{meta.error}</Typography>
      )}
    </Box>
  );
};

export const SubmitButton = ({
  isSubmitting,
  isDisabled = false,
  text = "Continue",
  ...props
}) => {
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      disabled={isSubmitting || isDisabled}
      sx={{ my: 1 }}
      {...props}
    >
      {isSubmitting ? (
        <>
          <CircularProgress size={22} />
        </>
      ) : (
        text
      )}
    </Button>
  );
};
